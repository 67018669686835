import { usePlayer, useRound, useStage } from "@empirica/core/player/classic/react";
import React, { useEffect, useRef } from "react";

export function RocketChat() {
    const player = usePlayer();
    const round = useRound();
    const stage = useStage();
    const iframeUrl = player.get("iframe_url"); // Retrieve iframe URL from player
    const authToken = player.get("authToken"); // Retrieve authToken from player
    const iframeRef = useRef(null); // Create a reference for the iframe

    // Function to send login command to iframe
    const sendLoginCommand = () => {
        if (iframeRef.current && authToken) {
            iframeRef.current.contentWindow.postMessage(
                { externalCommand: "login-with-token", token: authToken },
                "*"
            );
        }
    };

    useEffect(() => {
        if (iframeUrl && authToken) {
            sendLoginCommand();
        }
    }, [iframeUrl, authToken]); // Run when iframeUrl or authToken changes

    return (
        <div className="md:min-w-96 lg:min-w-128 xl:min-w-192 flex flex-col items-center space-y-10">
            <h1>Hi {player.get('participantID')}</h1>
            {stage.get('channelName')}

            {iframeUrl ? (
                <iframe
                    src={iframeUrl}
                    ref={iframeRef} // Assign the reference to the iframe
                    width="100%"
                    height="500"
                    title="RocketChat"
                    allow="camera; microphone"
                    onLoad={sendLoginCommand} // Ensure command is sent when iframe loads
                />
            ) : (
                <p>Loading chat...</p>
            )}
        </div>
    );
}
